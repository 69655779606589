        html {
            font-family: sans-serif;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
            font-size: 10px;
            -webkit-tap-highlight-color: transparent
        }

        .tk-futura-pt {
            font-family: "futura-pt", sans-serif;
        }

        @font-face {
            font-family: tk-futura-pt-n4;
            src: url(https://use.typekit.net/af/9b05f3/000000000000000000013365/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff2"), url(https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff"), url(https://use.typekit.net/af/9b05f3/000000000000000000013365/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("opentype");
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
            font-display: auto;
        }

        @font-face {
            font-family: tk-futura-pt-n3;
            src: url(https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("woff2"), url(https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("woff"), url(https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("opentype");
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            font-display: auto;
        }

        @font-face {
            font-family: futura-pt;
            src: url(https://use.typekit.net/af/9b05f3/000000000000000000013365/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff2"), url(https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff"), url(https://use.typekit.net/af/9b05f3/000000000000000000013365/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("opentype");
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
            font-display: auto;
        }

        @font-face {
            font-family: futura-pt;
            src: url(https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("woff2"), url(https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("woff"), url(https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("opentype");
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            font-display: auto;
        }

        body {
            margin: 0;
            margin: 0;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 14px;
            line-height: 1.42857;
            color: #333;
            background-color: #fff;
            font-family: "futura-pt";
            font-weight: 500;
            color: #b7b7b7
        }

        header,
        nav,
        section {
            display: block
        }

        a {
            background-color: transparent;
            color: #337ab7;
            text-decoration: none;
            color: #350223
        }

        h1 {
            font-size: 2em;
            margin: .67em 0;
            margin: .67em 0;
            font-size: 65px;
            color: #2d3033
        }

        img {
            border: 0;
            border: 0;
            vertical-align: middle
        }

        input,
        select,
        textarea {
            color: inherit;
            font-family: inherit;
            font-size: inherit;
            font-style: inherit;
            font-variant: inherit;
            font-weight: inherit;
            line-height: inherit;
            margin: 0
        }

        select {
            text-transform: none
        }

        input[type="submit"] {
            -webkit-appearance: button;
            cursor: pointer
        }

        input {
            line-height: normal
        }

        input[type="checkbox"] {
            box-sizing: border-box;
            padding: 0
        }

        textarea {
            overflow: auto;
            background-color: #b9b9b9;
            color: #555;
            font-size: 16px;
            border: 1px solid #b9b9b9;
            -moz-border-radius: 2px;
            -webkit-border-radius: 2px;
            border-radius: 2px;
            width: 70%;
            display: inline-block;
            padding-left: 10px;
            padding-right: 10px;
            vertical-align: middle;
            outline: none
        }

        @media print {
            * {
                background-color: transparent !important;
                color: #000 !important;
                box-shadow: none !important;
                text-shadow: none !important;
                background-position: initial initial !important;
                background-repeat: initial initial !important
            }

            a {
                text-decoration: underline
            }

            a[href]::after {
                content: ' (', attr(href), ')'
            }

            a[href^="#"]::after {
                content: ''
            }

            img {
                page-break-inside: avoid;
                max-width: 100% !important
            }

            img {
                max-width: 100% !important;
                max-width: 100% !important
            }

            p,
            h3 {
                orphans: 3;
                widows: 3
            }

            h3 {
                page-break-after: avoid
            }
        }

        * {
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box
        }

        html {
            font-size: 10px;
            -webkit-tap-highlight-color: transparent;
            font-family: sans-serif;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%
        }

        body {
            font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-size: 14px;
            line-height: 1.42857;
            color: #333;
            background-color: #fff;
            margin: 0;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            color: #333;
            background-color: #fff;
            font-family: "futura-pt";
            font-weight: 500;
            color: #b7b7b7
        }

        input,
        select,
        textarea {
            font-family: inherit;
            font-size: inherit;
            line-height: inherit
        }

        a {
            color: #337ab7;
            text-decoration: none;
            background-color: transparent;
            color: #337ab7;
            color: #350223
        }

        img {
            vertical-align: middle;
            border: 0
        }

        .img-responsive {
            display: block;
            max-width: 100%;
            height: auto
        }

        h1,
        h3 {
            font-family: inherit;
            font-weight: 500;
            line-height: 1.1;
            color: inherit
        }

        h1,
        h3 {
            margin-top: 20px;
            margin-bottom: 10px
        }

        h1 {
            font-size: 36px;
            font-size: 2em;
            margin: .67em 0;
            font-size: 65px;
            color: #2d3033
        }

        h3 {
            font-size: 24px;
            font-size: 28px;
            color: #2d3033;
            font-weight: 400
        }

        p {
            margin: 0 0 10px;
            margin: 0 0 10px;
            font-size: 14px;
            line-height: 22px;
            color: #363636
        }

        .text-left {
            text-align: left
        }

        .text-center {
            text-align: center
        }

        ul {
            margin-top: 0;
            margin-bottom: 10px
        }

        ul ul {
            margin-bottom: 0
        }

        .container {
            margin-right: auto;
            margin-left: auto;
            padding-left: 15px;
            padding-right: 15px
        }

        .container::before,
        .container::after {
            content: ' ';
            display: table
        }

        .container::after {
            clear: both
        }

        @media(min-width:768px) {
            .container {
                width: 750px
            }
        }

        @media(min-width:992px) {
            .container {
                width: 970px
            }
        }

        @media(min-width:1200px) {
            .container {
                width: 1170px
            }
        }

        .row {
            margin-left: -15px;
            margin-right: -15px
        }

        .row::before,
        .row::after {
            content: ' ';
            display: table
        }

        .row::after {
            clear: both
        }

        .col-sm-4,
        .col-md-4,
        .col-sm-6,
        .col-md-6,
        .col-md-10,
        .col-sm-12,
        .col-md-12 {
            position: relative;
            min-height: 1px;
            padding-left: 15px;
            padding-right: 15px
        }

        @media(min-width:768px) {

            .col-sm-4,
            .col-sm-6,
            .col-sm-12 {
                float: left
            }

            .col-sm-4 {
                width: 33.33333%
            }

            .col-sm-6 {
                width: 50%
            }

            .col-sm-12 {
                width: 100%
            }
        }

        @media(min-width:992px) {

            .col-md-4,
            .col-md-6,
            .col-md-10,
            .col-md-12 {
                float: left
            }

            .col-md-4 {
                width: 33.33333%
            }

            .col-md-6 {
                width: 50%
            }

            .col-md-10 {
                width: 83.33333%
            }

            .col-md-12 {
                width: 100%
            }

            .col-md-offset-1 {
                margin-left: 8.33333%
            }
        }

        label {
            display: inline-block;
            max-width: 100%;
            margin-bottom: 5px;
            font-weight: bold;
            width: 25%;
            vertical-align: middle
        }

        input[type="checkbox"] {
            margin: 4px 0 0;
            line-height: normal
        }

        .btn {
            display: inline-block;
            margin-bottom: 0;
            font-weight: normal;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
            background-image: none;
            border: 1px solid transparent;
            white-space: nowrap;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 1.42857;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            -webkit-user-select: none;
            margin-bottom: 0;
            touch-action: manipulation;
            border-radius: 4px;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-color: #350223;
            color: #fff;
            font-size: 13px;
            font-weight: 600;
            border: 1px solid rgba(255, 255, 255, .5);
            -moz-border-radius: 2px;
            -webkit-border-radius: 2px;
            border-radius: 2px;
            text-transform: uppercase
        }

        .clearfix::before,
        .clearfix::after {
            content: ' ';
            display: table
        }

        .clearfix::after {
            clear: both
        }

        .fa {
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            line-height: 1;
            font-family: FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font: normal normal normal 14px/1 FontAwesome;
            -moz-osx-font-smoothing: grayscale
        }

        .fa-lg {
            font-size: 1.33333333em;
            line-height: .75em;
            vertical-align: -15%;
            line-height: .75em
        }

        .fa-sign-in::before {
            content: '?'
        }

        p {
            font-size: 14px;
            line-height: 22px;
            color: #363636;
            margin: 0 0 10px;
            color: #363636
        }

        .beam-nav-sub {
            z-index: 10;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(59, 12, 35, .901961);
            opacity: .7;
            padding: 10px;
            background-color: rgba(59, 12, 35, .9);
            opacity: .7;
            border-bottom: 1px solid #af6259;
            line-height: 2em
        }

        .beam-nav-sub {
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: #af6259;
            line-height: 2em;
            z-index: 10;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(59, 12, 35, .9);
            opacity: .7;
            padding: 10px;
            border-bottom: 1px solid #af6259
        }

        .beam-nav-sub a {
            color: #fff;
            font-size: 14px;
            text-transform: uppercase;
            color: #fff
        }

        .headline {
            padding-top: 10px !important;
            padding-bottom: 12px !important;
            background-color: rgba(131,146,155,1) !important;
        }

        .headline-left {
            color: #363636;
            text-transform: uppercase;
            font-size: 12px;
            color: #363636
        }

        .headline-right {
            color: #363636;
            text-transform: uppercase;
            text-align: right;
            font-size: 12px;
            color: #363636
        }

        .headline-right a {
            color: #363636;
            font-weight: 600;
            color: #363636
        }

        .popup {
            margin-left: 10px
        }

        .popup h3 {
            font-size: 22px;
            line-height: 22px;
            color: #363636;
            text-transform: uppercase;
            font-weight: 600;
            color: #363636
        }

        .popup p {
            font-size: 16px;
            line-height: 22px;
            color: #363636;
            color: #363636
        }

        .popup p span {
            font-size: 14px
        }

        #header {
            position: fixed;
            width: 100%;
            z-index: 999;
            background-color: #fff;
            background-color: #fff
        }

        #header .header-content {
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: rgba(255, 255, 255, .14902);
            margin: 0 auto;
            max-width: 1170px;
            padding: 20px 0;
            width: 100%;
            transition: padding .3s;
            -webkit-transition: padding .3s;
            border-bottom: 1px solid rgba(255, 255, 255, .15);
            margin: 0 auto;
            padding: 20px 0;
            -moz-transition: padding .3s;
            -o-transition: padding .3s;
            -webkit-transition: padding .3s;
            transition: padding .3s
        }

        #header .logo {
            float: left;
            height: 50px !important;
            height: 50px !important
        }

        .menu-link {
            display: none;
            height: 44px;
            overflow: hidden;
            position: fixed;
            right: 5%;
            text-indent: 100%;
            top: 52px;
            white-space: nowrap;
            width: 44px;
            z-index: 99999;
            -moz-transition: all .3s;
            -o-transition: all .3s;
            -webkit-transition: all .3s;
            transition: all .3s
        }

        .menu {
            float: left;
            transition: all .3s ease;
            -webkit-transition: all .3s ease;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -ms-transition: all .3s ease;
            transition: all .3s ease
        }

        .menu ul {
            padding: 0;
            margin: 0;
            list-style: none;
            position: relative;
            display: inline-table;
            font-size: 15px;
            text-transform: uppercase;
            padding: 0;
            margin: 0
        }

        .menu ul li {
            padding: 0;
            padding: 0
        }

        .menu>ul>li {
            display: inline-block
        }

        .menu ul li a {
            display: block;
            text-decoration: none;
            font-size: .9em;
            color: rgba(54, 54, 54, .74902);
            color: #000;
            font-size: .9em;
            color: rgba(54, 54, 54, .75)
        }

        .menu ul li>a {
            height: 58px;
            padding: 19px 12px
        }

        .menu ul ul {
            display: none;
            position: absolute;
            top: 100%;
            min-width: 160px;
            background-color: #f4f4f4;
            -webkit-box-shadow: rgba(0, 0, 0, .74902) 0 7px 39px -10px;
            box-shadow: rgba(0, 0, 0, .74902) 0 7px 39px -10px;
            background-color: #f4f4f4;
            -webkit-box-shadow: 0 7px 39px -10px rgba(0, 0, 0, .75);
            -moz-box-shadow: 0 7px 39px -10px rgba(0, 0, 0, .75);
            box-shadow: 0 7px 39px -10px rgba(0, 0, 0, .75)
        }

        .menu ul ul>li {
            position: relative
        }

        .menu ul ul>li:first-child {
            border-top-style: none
        }

        .menu ul ul>li a {
            padding: 5px 15px 5px 10px;
            height: auto;
            background-color: #fff
        }

        @media(max-width:1025px) {
            a.menu-link {
                display: block;
                float: right;
                margin-top: 20px
            }

            .menu {
                clear: both;
                min-width: inherit;
                float: none
            }

            .menu,
            .menu>ul ul {
                overflow: scroll;
                max-height: 0;
                background-color: #fff
            }

            .menu ul {
                display: inline
            }

            .menu>ul {
                border-top-width: 1px;
                border-top-style: solid;
                border-top-color: #808080
            }

            .menu ul ul {
                -webkit-box-shadow: none;
                box-shadow: none
            }

            .menu li,
            .menu>ul>li {
                display: block
            }

            .menu li a {
                color: #000;
                display: block;
                padding: .8em;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-bottom-color: #c2c2c2;
                position: relative
            }

            .menu li.has-submenu>a::after {
                content: '+';
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                font-size: 1.65em;
                padding: .55em .5em
            }
            @media screen and (max-width: 768px) {
                .menu ul.sub-menu {
                  display: none; /* hide sub-menu by default on small screens */
                }
              
                .menu li.has-submenu.active ul.sub-menu {
                  display: block; /* show sub-menu when the parent li has the "active" class */
                }
              }
              

            .menu ul ul>li a {
                background-color: #fff;
                height: 58px;
                padding: 19px 18px 19px 30px
            }

            .menu ul ul {
                display: inherit;
                position: relative;
                left: auto;
                top: auto;
                border: none
            }
        }

        .menu-link {
            display: none;
            height: 44px;
            overflow: hidden;
            position: fixed;
            right: 5%;
            text-indent: 100%;
            top: 52px;
            white-space: nowrap;
            width: 44px;
            z-index: 99999;
            transition: all .3s;
            -webkit-transition: all .3s;
            -moz-transition: all .3s;
            -o-transition: all .3s;
            -webkit-transition: all .3s;
            transition: all .3s
        }

        .menu-link::before,
        .menu-link::after {
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            -webkit-transform: translateZ(0);
            -webkit-backface-visibility: hidden;
            -webkit-transition: -webkit-transform;
            transition: -webkit-transform
        }

        .menu-link::before {
            background-color: #350223;
            -webkit-transform: scale(1);
            transition: .3s;
            -webkit-transition: .3s
        }

        .menu-link::after {
            background-color: #350223;
            -webkit-transform: scale(0);
            transition: 0s;
            -webkit-transition: 0s
        }

        .menu-link span {
            background-color: #fff;
            bottom: auto;
            display: inline-block;
            height: 2px;
            left: 50%;
            position: absolute;
            right: auto;
            top: 50%;
            margin-top: -6px;
            width: 18px;
            z-index: 10;
            -webkit-transform: translateX(-50%) translateY(-50%);
            background-color: #fff;
            -moz-transform: translateX(-50%) translateY(-50%);
            -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%)
        }

        .menu-link span::before,
        .menu-link span::after {
            background-color: #fff;
            content: '';
            height: 100%;
            position: absolute;
            right: 0;
            margin-top: 12px;
            top: 0;
            width: 100%;
            -webkit-transform: translateZ(0);
            -webkit-backface-visibility: hidden;
            -webkit-transition: -webkit-transform .3s;
            transition: -webkit-transform .3s
        }

        .menu-link span::before {
            -webkit-transform: translateY(-6px) rotate(0deg)
        }

        .banner {
            background-image: url(/new/Content/Images/Web/stage-1.jpg);
            min-height: 500px;
            background-color: #350223;
            background-position: 50% -80px;
            background-repeat: no-repeat no-repeat;
            background-position: center -80px;
            background-repeat: no-repeat;
            background-color: #350223
        }

        .banner-text {
            float: right
        }

        .banner-text h1 {
            color: #fff;
            font-family: futura-pt;
            font-weight: 100;
            color: #fff;
            font-family: "futura-pt"
        }

        .banner-text p {
            color: #fff;
            font-size: 22px;
            font-weight: 300;
            line-height: 1.5;
            margin-bottom: 20px;
            color: #fff
        }

        .how-box h3 {
            color: #252525;
            font-size: 30px;
            font-weight: 600;
            line-height: 1.5;
            margin-bottom: 20px;
            color: #252525
        }

        .how-box h3 span {
            font-weight: 300
        }

        .selectmenu-box {
            background-color: #350223;
            background-color: #350223
        }

        label {
            width: 25%;
            vertical-align: middle;
            display: inline-block;
            max-width: 100%;
            margin-bottom: 5px;
            font-weight: bold
        }

        input[type="text"],
        input[type="password"]
        input[type="number"] {
            background-color: #b9b9b9;
            color: #555;
            font-size: 13px;    
            font-style: normal !important;
            border: 1px solid #b9b9b9;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            border-bottom-left-radius: 2px;
            height: 50px;
            width: 70%;
            display: inline-block;
            padding-left: 10px;
            padding-right: 10px;
            vertical-align: middle;
            outline: none
        }

        textarea {
            background-color: #b9b9b9;
            color: #555;
            font-size: 16px;
            border: 1px solid #b9b9b9;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            border-bottom-left-radius: 2px;
            width: 70%;
            display: inline-block;
            padding-left: 10px;
            padding-right: 10px;
            vertical-align: middle;
            outline: none;
            overflow: auto;
            background-color: #b9b9b9;
            color: #555;
            border: 1px solid #b9b9b9;
            -moz-border-radius: 2px;
            -webkit-border-radius: 2px;
            border-radius: 2px
        }

        .btn {
            background-color: #350223;
            color: #fff;
            font-size: 13px;
            font-weight: 600;
            border: 1px solid rgba(255, 255, 255, .498039);
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            border-bottom-left-radius: 2px;
            display: inline-block;
            text-transform: uppercase;
            margin-bottom: 0;
            font-weight: normal;
            text-align: center;
            vertical-align: middle;
            touch-action: manipulation;
            cursor: pointer;
            background-image: none;
            border: 1px solid transparent;
            white-space: nowrap;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 1.42857;
            border-radius: 4px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-color: #350223;
            color: #fff;
            border: 1px solid rgba(255, 255, 255, .5);
            -moz-border-radius: 2px;
            -webkit-border-radius: 2px;
            border-radius: 2px
        }

        .btn-large {
            padding: 15px 40px
        }

        .headline {
            background-color: #d7d7d7;
            padding-top: 8px;
            padding-bottom: 6px;
            background-color: #d7d7d7
        }

        .headline-left {
            color: #363636;
            text-transform: uppercase;
            font-size: 12px;
            color: #363636
        }

        .headline-right {
            color: #363636;
            text-transform: uppercase;
            text-align: right;
            font-size: 12px;
            color: #363636
        }

        .headline-right a {
            color: #363636;
            font-weight: 600;
            color: #363636
        }

        @media only screen and (max-width:414px) {
            .text-query-middle {
                text-align: center
            }

            .banner-text {
                margin-top: 80px
            }

            .beam-nav-sub-first {
                margin-top: 60px
            }

            #img-quote,
            #img-services,
            #img-range {
                display: none
            }
        }

        @media only screen and (max-width:600px) and (min-width:414px) {
            .text-query-middle {
                text-align: center
            }

            .banner-text {
                margin-top: 50px
            }

            .beam-nav-sub-first {
                margin-top: 90px
            }

            #img-quote,
            #img-services,
            #img-range {
                display: none
            }
        }

        @media only screen and (max-width:768px) and (min-width:601px) {
            .beam-nav-sub-first {
                margin-top: 60px
            }

            #img-quote,
            #img-services,
            #img-range {
                display: none
            }
        }

        @media only screen and (min-width:768px) {
            .banner-text {
                margin-top: 150px
            }
        }

        h1 {
            font-size: 65px;
            color: #2d3033;
            font-size: 2em;
            margin: .67em 0;
            color: #2d3033
        }

        h3 {
            font-size: 28px;
            color: #2d3033;
            font-weight: 400;
            color: #2d3033
        }

        a {
            color: #350223;
            background-color: transparent;
            color: #337ab7;
            text-decoration: none;
            color: #350223
        }

        body {
            font-family: futura-pt;
            font-weight: 500;
            color: #b7b7b7;
            margin: 0;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 14px;
            line-height: 1.42857;
            color: #333;
            background-color: #fff;
            font-family: "futura-pt";
            color: #b7b7b7
        }

        ul {
            margin: 0;
            padding: 0
        }

        ul li {
            list-style: none
        }

        .no-padding {
            padding: 0;
            padding: 0
        }

        .no-gutter [class*="col-"] {
            padding-right: 0;
            padding-left: 0
        }

        .required::after {
            content: '*';
            font-weight: bold;
            color: #350223;
            margin-left: 4px
        }

        .wf-active h1,
        .wf-active p {
            visibility: visible
        }

        @media screen and (max-width:1024px) {
            #header .header-content {
                width: 90%
            }
        }

        @media screen and (max-width:768px) {
            .banner-text {
                padding-top: 30%
            }

            .banner-text h1 {
                font-size: 42px
            }

            .banner-text p {
                font-size: 18px
            }
        }

        @media screen and (max-width:640px) {
            .banner-text {
                padding-top: 35%
            }
        }

        @media screen and (max-width:480px) {
            .banner-text {
                padding-top: 45%
            }

            .banner-text h1 {
                font-size: 32px
            }
        }

        @media screen and (max-width:320px) {
            .banner-text {
                padding-top: 55%
            }
        }

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

body {
    overflow-x: hidden;
}

::ng-deep .modal.fade.show {
    opacity: unset !important;
  }

  @media screen and (max-width: 450px) {
   
    #header {
       width: 100%;
    }

    .menu-link {
        position: absolute;
    }

    #menu {
        position: absolute;
        right: 0px;
    }

    html {
        overflow-x: visible; /* Or auto, depending on your preference */
    }

    .sign-up-section {
        width: 102%;
        margin-right: 0px;
    }

    .user-profile {
        left: 40% !important;
    }
    .guest-quote-menu {
      margin-top: 60px;
    }
    body {
        overflow: auto;
    }
    #panelsList {
        min-width: 1500px;
    }

    .content-area {
        width: 1282px;
        margin-left: 10px;
    }

    app-my-account {
        .main-body {
        padding: 0px !important
    }

    .account-section {
        width: auto;
    }

    input[type="text"], .form .item textarea {
        width: 200px;
    }
}
 }

 .fade {
    opacity: 0.9 !important;
}
